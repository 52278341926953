import React, { useMemo } from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import { Layout } from '../components/Layout'
import { Posts } from '../components/Posts'
import { SEO } from '../components/SEO'
import { Heading } from '../components/Heading'
import { Hero } from '../components/Hero'
//import { projectsList } from '../data/projectsList'
import { getSimplifiedPosts } from '../utils/helpers'
import config from '../utils/config'
import { scholar } from '../data/links'
import gs_logo from '../assets/scholar_logo.png'


export default function Index({ data }) {
  const latest = data.latest.edges
  const highlights = data.highlights.edges
  const simplifiedLatest = useMemo(() => getSimplifiedPosts(latest), [latest])
  const simplifiedHighlights = useMemo(
    () =>
      getSimplifiedPosts(highlights, { shortTitle: true, thumbnails: true }),
    [highlights]
  )

  return (
    <div>
      <Helmet title={config.siteTitle} />
      <SEO />

      <div className="container">
        <div className="hero-wrapper">
          <Hero title="I'm Colin Birkenbihl," underscore={"welcome to my digital lab."} index>
            <p className="hero-description small width">
            I solve biomedical challenges using data, artificial intelligence, and machine learning.
            </p>
            {/*I research diseases using data, artificial intelligence, and statistics.*/}
          </Hero>
          <div className="front-decoration">
            <img
              src="/front_image.png"
              alt=""
              className="hero-image"
              title=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <section className="segment first">
          <Heading title="Latest first/last author publications" slug="/publications" />

          <Posts data={simplifiedLatest} newspaper /> 
          {/* look at components/post.js to edit this section */}
          {/*<p>For a complete list of my publications please look at my {''}<a
                  href={scholar}
                  title="Google Scholar profile"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={scholar}
                >
                  <p>Google Scholar profile</p>
                  <img src={gs_logo} alt="Google Scholar profile" />
  </a>.</p>*/}
        </section>

        <section className="segment large">
          <Heading title="Selected Papers" />

          <div className="highlight-preview">
            {simplifiedHighlights.map((post) => {
              return (
                <div className="muted card flex" key={`popular-${post.slug}`}>
                  {post.thumbnail && <Img fixed={post.thumbnail} />}
                  <div>
                    <time>{post.date}</time>
                    <Link className="card-header" to={post.slug}>
                      {post.title}
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        </section>

        {/* here was the project section */}

        <section className="segment large">
          <Heading title="Newsletter" />
          <p>
            Sign up to be notified of new research publications.
          </p>
          <div className='newsletter-button-index'>
            <a
              href="https://birkenbihl.substack.com"
              target="_blank"
              rel="noopener noreferrer"
              className="button large highlighted"
            >
              Subscribe to the Newsletter
            </a>
          </div>
        </section>
      </div>
    </div>
  )
}

Index.Layout = Layout

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      limit: 6
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { or_replacement: { gte: 1 } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            tags
            categories
            template
          }
        }
      }
    }
    highlights: allMarkdownRemark(
      limit: 12
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { categories: { eq: "Highlight" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            shortTitle
            tags
            thumbnail {
              childImageSharp {
                fixed(width: 45, height: 45) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
